@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.applyNowBtnHero {
	background-color: #ff0000;
	position: fixed;
	right: 0;
	font-size: 16px;
	font-weight: 900;
}

.applyNow-container h1.fs25px {
	font-size: 25px;
}

.applyNow-container h1.fs20px {
	font-size: 20px;
}

.text-red {
	color: #ff0000;
}

.bg-logo-parallax {
	background-image: url('./assets/3d visas.png');
	background-size: 100%, cover;
	background-attachment: fixed;
	background-position: center;
}

.parallax-effect {
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.8);
	padding: 100px;
}

.mr-0 {
	margin-right: 0;
}

.h1-custom {
	font-size: 3rem;
}

.text-rotate-label {
	color: #fff;
	width: auto;
	display: flex;
	margin-left: 1.5%;
	position: absolute;
	background: none;
	transform-origin: 0 0;
	transform: rotate(-90deg);
	text-align: center;
	font-family: 'Roboto';
	margin-top: calc(95vh/1.4);
	letter-spacing: 10px;
}

.container {
	width: 80%;
	margin: auto;
}


.logo img {
	width: 150px;
}

.section1 {
	background-color: rgba(255, 255, 255, 0.779);
	width: 95%;
	margin-left: 5%;
	position: fixed;
}
.section1.full-width {
	width: 100%;
	margin-left: 0;
	background-color: #ffffff;
}

.w-40px {
	width: 40px !important;
}

.mr-3 {
	margin-right: 15px !important;
}

.Dream_To_Fly {
	width: 110%;
}

.sectionOverlay {
	background-color: transparent;
	width: 95%;
	margin-left: 5%;
	height: inherit;
}

.form-label {
	font-size: 20px;
	color: #070163;
}

.align-center-flex {
	align-items: center;
	justify-content: flex-start;
}

.sectionOverlay .h-calc {
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
}

.sectionOverlay h3 {
	font-family: 'Roboto';
	font-size: 60px;
}



.PopupSize {
	width: 300px;
}

.w-550px {
	width: 550px;
}

.h-300px {
	height: 300px;
}

.sectionOverlay h4 {
	font-family: 'Roboto';
	/*	font-size: 50px;*/
	font-weight: 400;
}


.sectionOverlay span {
	display: block;

}

.sectionOverlay span.blockKeywords {
	text-align: center;
	font-family: 'Roboto';
	font-size: 130px;
	color: #df0015;
	font-weight: 900;
	line-height: 0.6;
}

.sectionOverlay span.blockKeywords-light {
	font-family: 'Roboto';
	font-size: 85px;
	font-weight: 900;
	line-height: 1;
}

.section2 {
	background-image: url('./asset/manonairport.jpg');
	height: 100vh;
	background-size: 95% 130%, cover, cover;
	background-repeat: no-repeat;
	background-position: right;
	background-position-y: -50px;
	top: 0;
	padding: 0;
}

.mainSection5 {
	background-image: url('./assets/natalya-zaritskaya-SIOdjcYotms-unsplash.jpg');
	background-size: 100%, cover;
	background-attachment: fixed;
}

.mainSection5 .bg-black-transparent {
	background-color: rgba(255, 255, 255, 0.6);
	padding: 100px;
}

.fg-yellow {
	color: #27275a;
}

.gradient-counters {
	-webkit-text-fill-color: transparent;
	background: transparent linear-gradient(90deg, #27275a, #2d405b) 0 0 no-repeat padding-box;
	-webkit-background-clip: text;
	font-weight: 700;
}

.fs-3rem {
	font-size: 3rem;
}

.mainSection {
	height: inherit;
	background-image: url('./assets/bluebarwithtext.png');
	/* background-color: #070163; */
	background-size: 5% 100%, cover, cover;
	background-repeat: no-repeat;
	background-position: left;
	top: 0;
	z-index: 999;
}

.space-between {
	align-items: center;
	justify-content: space-between;
}

.menu {
	margin-left: auto;
	margin-right: 30px;
	width: 70%;
	float: right;
}

.customBTN.fs-17px {
	font-size: 17px;
}

.menu a {
	text-decoration: none;
	color: #010763;
	font-family: 'Poppins', sans-serif;
	font-weight: 500 !important;
	font-size: 20px;
}

.mainSection2 {
	/* height: 100vh	; */
	background: rgb(208, 227, 231);
	background: radial-gradient(circle, rgba(208, 227, 231, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.p-cursor {
	cursor: pointer;
}

.zoomEffect:hover {
	/* animation: zoom-in-zoom-out 0.5s ease-out infinite; */
	transform: scale(1.1, 1.1);
}



/* @keyframes zoom-in-zoom-out {
	0% {
	  transform: scale(1, 1);
	}
	100% {
	  transform: scale(1.2, 1.2);
	}
  } */

.logo2 {
	width: 100%;
	text-align: center;
}

.logo2 img {
	width: 60%;
	margin: 20px 0;
}

.logo3 img {
	width: 40%;
	margin: 20px 0;
}

.Poppins {
	font-family: 'Poppins', sans-serif;
}

.text-justify {
	text-align: justify;
}

.text-right {
	text-align: right;
}

.btn.customBTN {
	background-color: #ff0000;
}

.mainSection3 {
	background-color: #fff;
}

.global {
	background-color: #010763;
	background-image: url('./asset/worldmap.png');
	background-size: 100% 100%, cover, cover;
	background-repeat: no-repeat;
	height: 85vh;
}

.indent-170 {
	text-indent: -170px;
}

.globeSubSection {
	height: 50%;
}

.fg-white {
	color: #fff;
}

.fs-50px {
	font-size: 50px;
}

.mainSection3 {
	padding-bottom: 10%;
}

.floating-cards {
	height: 120%;
	width: 25%;
	padding: 30px;
}

.content {
	border: 1px solid #21405D;
	border-radius: 30px;
	height: inherit;
	overflow: hidden;
	background-color: #21405D;
}

/*.w-140 {
	width: 140%;
}*/

.rounded-corners {
	border-radius: 30px;
	overflow: hidden;
}

.pl-5 {
	padding-left: 50px;
}


.mainSection4 {
	padding-top: 3%;
	padding-bottom: 3%;
}

.fg-theme {
	color: #21405D;
}

.fg-themeDark {
	color: #1c3852;
}

.h-200 {
	height: 200px;
}

.bg-theme {
	background: #21405D;
}

.gradient-bg-theme {
	background: linear-gradient(93deg, rgba(39, 39, 90, 1) 0%, rgba(45, 64, 91, 1) 100%);
}

.icon {
	width: 85px;
	padding: 10px;
	background-color: rgba(255, 255, 255, 0.2);
}

.mainSection4Container .icon {
	width: fit-content;
	padding: 10px;
	background-color: rgba(255, 255, 255, 0.2);
}

.padHorizontal-30 {
	padding: 5px 30px;
}



.text-left {
	text-align: left;
}

.mainSection4 .rounded-corner {
	border-radius: 20px;
	overflow: hidden;
}

.btn-rounded {
	border-radius: 40px;
	padding: 15px 30px;
}

.btn h1,
.btn h2,
.btn h3,
.btn h4,
.btn h5 {
	margin: 0;
	margin-bottom: 5px;
}

.p-3 {
	padding: 15px;
}

.bg-ash {
	background-color: #aaaaaa;
	color: #ffffff;
}

.fg-ash {
	color: #aaaaaa;
}

.bg-grey {
	background-color: #555555;
	color: #ffffff;
}

.fg-grey {
	color: #555555;
}

/*.TestContainer {
	width: 100%;
	height: 800px;
}*/

.applyNow-Image {
	/* margin-top: 30px; */
	width: 100% !important;
	margin-left: -43px;
}

.applyNow-container {
	overflow: hidden;
}




.link-footer {
	color: #21405D;
	display: block;
	text-decoration: none;
	font-size: 20px;
	font-family: 'Poppins';
	margin-top: 20px;
	margin-bottom: 20px;
}

.step-container {
	margin-top: 150px;
	height: 500px;
	background-image: url('./asset/completeonlineformbluebox.png');
	background-size: 100% 100%, cover, cover;
}

.Circle {
	width: 100px;
	height: 100px;
	margin: auto;
	padding: 25px;
	background-color: #ff0000;
	border-radius: 50%;
	margin-top: -35px;
}

.textContent {
	margin-top: 100px;
	margin-bottom: 100px;
}

.button {
	bottom: 0;
	height: 100px;
	margin: auto;
	padding: 25px;
	background-color: #ff0000;
	border-radius: 50px;
}

.text-black {
	color: #555555;
}


.material-symbols-outlined {
	font-variation-settings:
		'FILL' 0,
		'wght' 400,
		'GRAD' 0,
		'opsz' 24
}

.f-reverse {
	flex-direction: row-reverse;
}


/*.subHeading*/

.pl-3 {
	padding-left: 30px;
}

.pr-3 {
	padding-right: 30px;
}


.floating-cards {
	height: 120%;
}

.rounded-corners {
	height: 80%;
}

.rounded-corners img.w-140 {
	height: 100%;
}

.rounded-corner.bg-red h1 {
	line-height: 2;
}

.mainSection4Container .rounded-corner.icon img {
	width: 52px;
	height: 52px;
}

.backgroundTempFooterSection {
	background-image: url('./assets/19769981.png');
	background-repeat: no-repeat;
	background-size: 100%, cover;
	background-position: bottom;
}

.footer-desc {
	font-size: 20px;
	font-weight: 300;
	line-height: 1.5;
}

.copyright-desc {
	font-size: 16px;
	font-weight: 300;
}

.footer-heading {
	font-size: 25px;
	font-weight: 500;
}

.link-footer {
	font-size: 18px;
	font-weight: 300;
	line-height: 2;
}

.bg-footer-transparency {
	/* background-color: rgba(30, 33, 52, 0.98	); */
}


.keyPoints {
	font-size: 25px;
	line-height: 0.3;
}

.footerIcon {
	width: 20px;
	margin-right: 10px;
}

.material-symbols-outlined {
	font-size: 40px;
}

.d-space-between {
	align-items: center;
	justify-content: space-between;
}

.w-50px {
	width: 50px;
	border-radius: 50%;
}


.Circle h1 {
	margin-top: 5px;
}

.customBTN h3,
.customBTN h1 {
	margin-bottom: 0;
	font-family: 'Poppins', sans-serif;
}


.btn.fw-500 {
	font-weight: 700 !important;
}


@media (min-width: 1360px) and (max-width: 1920px) {
	.custom-h4 {
		font-size: 1.4rem;
	}
	.zoomHandler {
		/* text-align: left; */
		margin-left: 50px;
	}

	.section2 {
		background-image: url('./asset/manonairport.jpg');
		height: 100vh;
		background-size: 95% 120%, cover, cover;
		background-repeat: no-repeat;
		background-position: right;
		background-position-y: -50px;
		top: 0;
		padding: 0;
	}

	.menu.d-flex.navbarMenuAreaHandler {
		display: none !important;
	}

	.menu {
		width: 35%;
	}

	.mainSection4Container h3 {
		font-size: 22px !important;
	}

	.floating-cards h1 {
		font-size: 24px;
	}

	.floating-cards .pl-5 {
		padding-left: 30px !important;
	}

	.rounded-corners img.w-140 {
		height: 100%;
	}

	.applyNow-container h1 {
		font-size: 30px;
	}

	.step-container {
		height: 400px;
		margin-top: 100px;
	}

	.textContent {
		margin: 60px 0;
	}

	.textContentExtra {
		margin-top: 90px;
		margin-bottom: 90px;
	}

	.fg-theme2 {
		color: #4d647a;
	}

	.sectionOverlay {
		background-color: transparent;
		width: 95%;
		margin-left: 5%;
		height: inherit;
	}

	.align-center-flex {
		align-items: center;
		justify-content: flex-start;
	}

	.sectionOverlay .h-calc {
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
	}

	.sectionOverlay h3 {
		font-family: 'Roboto';
		font-size: 50px;
	}

	.w-550px {
		width: 550px;
	}

	.sectionOverlay h4 {
		font-family: 'Roboto';
		/*	font-size: 50px;*/
		font-weight: 400;
	}


	.sectionOverlay span {
		display: block;

	}

	.sectionOverlay span.blockKeywords {
		text-align: center;
		font-family: 'Roboto';
		font-size: 110px;
		color: #df0015;
		font-weight: 900;
		line-height: 0.6;
	}

	.sectionOverlay span.blockKeywords-light {
		font-family: 'Roboto';
		font-size: 75px;
		font-weight: 900;
		line-height: 1;
	}

	.para {
		font-size: 20px;
	}



	.footer-desc {
		font-size: 20px;
		font-weight: 300;
		line-height: 1.5;
	}

	.link-footer {
		font-size: 20px;
	}


	.footer-heading {
		font-size: 25px;
		font-weight: 500;
	}

	.floating-cards .content h1 {
		font-size: 20px;
	}

	.PopupSize .material-symbols-outlined {
		font-size: 22px;
		margin-top: 10px;
	}





	.PopupSize {
		width: 350px;
	}
}



@media (min-width: 1360px) and (max-width: 1439.9px) {
	.Dream_To_Fly {
		width: 90%;
	}
}


@media (min-width: 1080px) and (max-width: 1359.9px) {



	.Dream_To_Fly {
		width: 90%;
	}

	.sectionOverlay .zoomHandler {
		zoom: 1;
	}

	/* .floating-cards .content h1 {
		font-size: 20px;
	} */


	.text-rotate-label {
		color: #fff;
		width: auto;
		display: flex;
		margin-left: 1.5%;
		position: absolute;
		background: none;
		transform-origin: 0 0;
		transform: rotate(-90deg);
		text-align: center;
		font-family: 'Roboto';
		margin-top: calc(70vh/1.4);
		letter-spacing: 7px;
	}

	.link {
		font-size: 12px;
	}

	.menu {
		width: 35%;
	}

	.section2 {
		height: 90vh;
		background-position-y: -10px;
	}

	.menu.d-flex.navbarMenuAreaHandler {
		display: none !important;
	}

	.globeSubSection>div.floating-cards {
		display: none;
		width: 33%;
	}

	.globeSubSection>div.floating-cards:first-child {
		display: block;
	}

	.globeSubSection>div.floating-cards:nth-child(2) {
		display: block;
	}

	.globeSubSection>div.floating-cards:nth-child(3) {
		display: block;
	}


	.mainSection4Container h3 {
		font-size: 22px;
	}

	.floating-cards h1 {
		font-size: 22px;
	}

	.floating-cards .pl-5 {
		padding-left: 30px !important;
	}

	.rounded-corners img.w-140 {
		height: 100%;
	}

	.applyNow-container h1 {
		font-size: 25px;
	}

	.applyNow-container .button h1 {
		font-size: 20px;
	}

	.applyNow-container .Circle h1 {
		font-size: 35px;
	}

	.step-container {
		height: 350px;
		margin-top: 50px;
	}

	.textContent {
		margin: 45px 0;
	}

	.indent-170 {
		text-indent: -70px;
	}

	.sectionOverlay {
		background-color: transparent;
		width: 95%;
		margin-left: 5%;
		height: inherit;
	}

	.align-center-flex {
		align-items: center;
		justify-content: flex-start;
	}

	.sectionOverlay .h-calc {
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
	}

	.sectionOverlay h3 {
		font-family: 'Roboto';
		font-size: 30px;
	}

	.w-550px {
		width: 550px;
	}

	.sectionOverlay h4 {
		font-family: 'Roboto';
		font-size: 20px;
		font-weight: 400;
	}


	.sectionOverlay span {
		display: block;

	}

	.sectionOverlay span.blockKeywords {
		text-align: center;
		font-family: 'Roboto';
		font-size: 80px;
		color: #df0015;
		font-weight: 900;
		line-height: 0.6;
	}

	.sectionOverlay span.blockKeywords-light {
		font-family: 'Roboto';
		font-size: 50px;
		font-weight: 900;
		line-height: 1;
	}

	.para {
		font-size: 20px;
	}


	.footer-desc {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.5;
	}

	.link-footer {
		font-size: 12px;
	}

	.footer-heading {
		font-size: 16px;
		font-weight: 500;
	}

	.form-label {
		font-size: 15px;
	}

	.PopupSize .material-symbols-outlined {
		font-size: 18px;
		margin-top: 10px;
	}





	.PopupSize {
		width: 350px;
	}

}



@media (min-width: 768px) and (max-width: 1079.9px) {



	.Dream_To_Fly {
		width: 90%;
	}


	.text-rotate-label {
		color: #fff;
		width: auto;
		display: flex;
		margin-left: 1.2%;
		position: absolute;
		background: none;
		transform-origin: 0 0;
		transform: rotate(-90deg);
		text-align: center;
		font-family: 'Roboto';
		margin-top: calc(70vh/1.4);
		letter-spacing: 7px;
	}

	.menu.d-flex.navbarMenuArea {
		display: none !important;
	}

	.menu.d-flex.navbarMenuArea.visible {
		display: flex !important;
	}

	.section2 {
		height: 70vh;
		background-position-y: 0px;
	}

	.navbarMenuArea {
		flex-direction: column;
		position: absolute;
		right: 0;
		width: 300px;
		text-align: left;
		margin-top: 70px;
		padding: 15px;
		background: #ffffff;
	}

	.navbarMenuArea .link {
		padding: 5px;
	}

	.space-between.navbarMenuArea {
		align-items: flex-start;
		justify-content: center;
	}

	.globeSubSection>div.floating-cards {
		display: none;
		width: 33%;
	}

	.globeSubSection>div.floating-cards:first-child {
		display: block;
	}

	.globeSubSection>div.floating-cards:nth-child(2) {
		display: block;
	}

	.globeSubSection>div.floating-cards:nth-child(3) {
		display: block;
	}

	.customBTN h1 {
		font-size: 25px;
	}

	.global {
		height: 60vh;
	}

	.mainSection4Container h3 {
		font-size: 22px;
	}

	.floating-cards h1 {
		font-size: 22px;
	}

	.rounded-corners img.w-140 {
		height: 100%;
	}

	.applyNow-container h1 {
		font-size: 25px;
	}

	.applyNow-container .button h1 {
		font-size: 20px;
		line-height: 0;
	}

	.applyNow-container .Circle h1 {
		font-size: 35px;
	}

	.step-container {
		height: 250px;
		margin-top: 50px;
	}

	.textContent {
		margin: 20px 0;
	}

	.applyNow-container .button {
		height: auto;
	}

	.indent-170 {
		text-indent: -70px;
	}

	.sectionOverlay h3 {
		font-family: 'Roboto';
		font-size: 35px;
	}

	.w-550px {
		width: 450px;
	}

	.sectionOverlay h4 {
		font-family: 'Roboto';
		font-size: 18px;
		font-weight: 400;
	}


	.sectionOverlay span {
		display: block;

	}

	.sectionOverlay span.blockKeywords {
		text-align: center;
		font-family: 'Roboto';
		font-size: 65px;
		color: #df0015;
		font-weight: 900;
		line-height: 0.6;
	}

	.sectionOverlay span.blockKeywords-light {
		font-family: 'Roboto';
		font-size: 45px;
		font-weight: 900;
		line-height: 1;
	}

	.align-center-flex {
		justify-content: center;
	}

	.para {
		font-size: 20px;
	}


	.footer-desc {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.5;
	}

	.link-footer {
		font-size: 10px;
	}

	.footer-heading {
		font-size: 16px;
		font-weight: 500;
	}

	.logo3 img {
		width: 65%;
	}

	.applyNowBtnHero {
		position: absolute;
		right: 0;
		font-size: 10px;
	}

	.PopupSize .material-symbols-outlined {
		font-size: 18px;
		margin-top: 10px;
	}
}



@media (min-width: 480px) and (max-width: 767.9px) {



	.Dream_To_Fly {
		width: 90%;
	}

	.logo3 img {
		width: 75%;
	}

	.card.form {
		width: 60%;
		position: absolute;
		left: 20%;
		top: 100px;
	}

	.para {
		font-size: 18px;
	}

	.text-rotate-label {
		color: #fff;
		width: auto;
		display: flex;
		margin-left: 0.3%;
		position: absolute;
		background: none;
		transform-origin: 0 0;
		transform: rotate(-90deg);
		text-align: center;
		font-family: 'Roboto';
		margin-top: calc(60vh/1.4);
		letter-spacing: 5px;
	}

	.menu.d-flex.navbarMenuArea {
		display: none !important;
	}

	.menu.d-flex.navbarMenuArea.visible {
		display: flex !important;
	}

	.section2 {
		background-position-y: 0px;
		height: 60vh;
	}

	.navbarMenuArea {
		flex-direction: column;
		position: absolute;
		right: 0;
		width: 300px;
		text-align: left;
		margin-top: 70px;
		padding: 15px;
		background: #ffffff;
	}

	.navbarMenuArea .link {
		padding: 5px;
	}

	.space-between.navbarMenuArea {
		align-items: flex-start;
		justify-content: center;
	}


	.mainSection3 .heading.fs-50px {
		text-align: center;
		font-size: 25px;
	}

	.mainSection3 .subHeading {
		text-align: center;
		font-size: 20px;
	}

	.globeSubSection>div.floating-cards {
		display: none;
		width: 49.4%;
	}

	.globeSubSection>div.floating-cards:first-child {
		display: block;
	}

	.globeSubSection>div.floating-cards:nth-child(2) {
		display: block;
	}

	.customBTN h1 {
		font-size: 20px;
	}

	.global {
		height: 65vh;
	}

	.text-center-mobile {
		text-align: center;
	}

	.mainSection4Container h3 {
		font-size: 22px;
	}

	.w-35 {
		width: 100%;
	}

	.floating-cards h1 {
		font-size: 22px;
	}

	.rounded-corners img.w-140 {
		height: 100%;
	}

	.applyNow-container h1 {
		font-size: 20px;
	}

	.applyNow-container .button h1 {
		font-size: 14px;
		line-height: 0;
	}

	.applyNow-container .Circle h1 {
		font-size: 35px;
	}

	.step-container {
		height: 235px;
		margin-top: 50px;
	}

	.textContent {
		margin: 20px 0;
	}

	.applyNow-container .button {
		height: auto;
	}

	.indent-170 {
		text-indent: -70px;
	}

	.sectionOverlay h3 {
		font-family: 'Roboto';
		font-size: 30px;
	}

	.w-550px {
		width: 300px;
	}

	.sectionOverlay h4 {
		font-family: 'Roboto';
		font-size: 12px;
		font-weight: 400;
	}


	.sectionOverlay span {
		display: block;

	}

	.sectionOverlay span.blockKeywords {
		text-align: center;
		font-family: 'Roboto';
		font-size: 55px;
		color: #df0015;
		font-weight: 900;
		line-height: 0.6;
	}

	.sectionOverlay span.blockKeywords-light {
		font-family: 'Roboto';
		font-size: 40px;
		font-weight: 900;
		line-height: 1;
	}

	.align-center-flex {
		justify-content: center;
	}


	.footer-desc {
		font-size: 112px;
		font-weight: 300;
		line-height: 1.5;
	}

	.link-footer {
		font-size: 12px;
	}

	.applyNowBtnHero {
		position: absolute;
		right: 0;
		font-size: 10px;
	}

	.PopupSize .material-symbols-outlined {
		font-size: 18px;
		margin-top: 10px;
	}
}



@media screen and (max-width: 479.9px) {



	.Dream_To_Fly {
		width: 90%;
	}

	.logo3 img {
		width: 75%;
	}

	.card.form {
		width: 90%;
		position: absolute;
		left: 5%;
		top: 100px;
	}

	.para {
		font-size: 16px;
	}

	.text-rotate-label {
		color: #fff;
		width: auto;
		display: flex;
		margin-left: 0.5%;
		position: absolute;
		background: none;
		transform-origin: 0 0;
		transform: rotate(-90deg);
		text-align: center;
		font-family: 'Roboto';
		font-size: 10px;
		margin-top: calc(60vh/1.4);
		letter-spacing: 5px;
	}

	.menu.d-flex.navbarMenuArea {
		display: none !important;
	}

	.menu.d-flex.navbarMenuArea.visible {
		display: flex !important;
	}

	.section2 {
		/*		margin-top: 100px;*/
		height: 60vh;
		background-position-y: 70px;
	}

	.navbarMenuArea {
		flex-direction: column;
		position: absolute;
		right: 0;
		width: 300px;
		text-align: left;
		margin-top: 70px;
		padding: 15px;
		background: #ffffff;
	}

	.navbarMenuArea .link {
		padding: 5px;
	}

	.space-between.navbarMenuArea {
		align-items: flex-start;
		justify-content: center;
	}


	.mainSection3 .heading.fs-50px {
		text-align: center;
		font-size: 25px;
	}

	.mainSection3 .subHeading {
		text-align: center;
		font-size: 20px;
	}

	.globeSubSection>div.floating-cards {
		display: none;
		width: 100%;
	}

	.globeSubSection>div.floating-cards:first-child {
		display: block;
	}

	.customBTN h1 {
		font-size: 20px;
	}

	.global {
		height: 70vh;
	}

	.text-center-mobile {
		text-align: center;
	}

	.mainSection4Container h3 {
		font-size: 22px;
	}

	.w-35 {
		width: 100%;
	}

	.link {
		font-size: 15px;
	}

	.floating-cards h1 {
		font-size: 22px;
	}

	.rounded-corners img.w-140 {
		height: 100%;
	}

	.applyNow-container h1 {
		font-size: 18px;
	}

	.applyNow-container .button h1 {
		font-size: 12px;
		line-height: 0;
	}

	.applyNow-container .Circle h1 {
		font-size: 35px;
	}

	.step-container {
		height: 180px;
		margin-top: 50px;
	}

	.textContent {
		margin: 0px 0;
	}

	.applyNow-container .button {
		height: auto;
		padding: 15px;
	}

	.indent-170 {
		text-indent: -70px;
	}

	.sectionOverlay h3 {
		font-family: 'Roboto';
		font-size: 30px;
	}

	.w-550px {
		width: 300px;
	}

	.sectionOverlay h4 {
		font-family: 'Roboto';
		font-size: 12px;
		font-weight: 400;
	}

	.align-center-flex {
		justify-content: center;
	}

	.sectionOverlay span {
		display: block;

	}

	.sectionOverlay span.blockKeywords {
		text-align: center;
		font-family: 'Roboto';
		font-size: 55px;
		color: #df0015;
		font-weight: 900;
		line-height: 0.6;
	}

	.sectionOverlay span.blockKeywords-light {
		font-family: 'Roboto';
		font-size: 40px;
		font-weight: 900;
		line-height: 1;
	}


	.footer-desc {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.5;
	}

	.link-footer {
		font-size: 12px;
	}

	.applyNowBtnHero {
		position: absolute;
		right: 0;
		font-size: 10px;
	}

	.PopupSize .material-symbols-outlined {
		font-size: 18px;
		margin-top: 10px;
	}
}




/*  Animation  */



.animDelay1s {
	animation-delay: 1s;
}


.animDelay2s {
	animation-delay: 2s;
}


.animDelay3s {
	animation-delay: 3s;
}


.animDelay4s {
	animation-delay: 4s;
}


.animDelay5s {
	animation-delay: 5s;
}


.animDelay6s {
	animation-delay: 6s;
}


.animDelay7s {
	animation-delay: 7s;
}


.animDelay8s {
	animation-delay: 8s;
}


.animDelay9s {
	animation-delay: 9s;
}


.animDelay10s {
	animation-delay: 10s;
}






@keyframes Blink1 {

	0%,
	50%,
	100% {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}

.Blink1 {
	animation: Blink1 2s ease 0s 1 normal forwards;
}

@keyframes Blink2 {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.2;
	}

	100% {
		opacity: 1;
	}
}

.Blink2 {
	animation: Blink2 2s ease 0s 1 normal forwards;
}



@keyframes BounceBottom {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateY(45px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateY(24px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateY(12px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateY(6px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateY(4px);
	}

	25%,
	55%,
	75%,
	87% {
		animation-timing-function: ease-out;
		transform: translateY(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0px);
	}
}

.BounceBottom {
	animation: BounceBottom 2s ease 0s 1 normal forwards;
}


@keyframes BounceLeft {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateX(-48px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateX(-26px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateX(-13px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateX(-6.5px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateX(-4px);
	}

	25%,
	55%,
	75%,
	87%,
	98% {
		animation-timing-function: ease-out;
		transform: translateX(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateX(0px);
	}
}

.BounceLeft {
	animation: BounceLeft 2s ease 0s 1 normal forwards;
}



@keyframes BounceRight {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateX(48px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateX(26px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateX(13px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateX(6.5px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateX(4px);
	}

	25%,
	55%,
	75%,
	87%,
	98% {
		animation-timing-function: ease-out;
		transform: translateX(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateX(0px);
	}
}

.BounceRight {
	animation: BounceRight 2s ease 0s 1 normal forwards;
}


@keyframes BounceTop {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateY(-45px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateY(-24px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateY(-12px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateY(-6px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateY(-4px);
	}

	25%,
	55%,
	75%,
	87% {
		animation-timing-function: ease-out;
		transform: translateY(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0px);
	}
}

.BounceTop {
	animation: BounceTop 2s ease 0s 1 normal forwards;
}




@keyframes JelloHorizontal {
	0% {
		transform: scale3d(1, 1, 1);
	}

	30% {
		transform: scale3d(1.25, 0.75, 1);
	}

	40% {
		transform: scale3d(0.75, 1.25, 1);
	}

	50% {
		transform: scale3d(1.15, 0.85, 1);
	}

	65% {
		transform: scale3d(0.95, 1.05, 1);
	}

	75% {
		transform: scale3d(1.05, 0.95, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

.JelloHorizontal {
	animation: JelloHorizontal 2s ease 0s 1 normal forwards;
}


@keyframes JelloVertical {
	0% {
		transform: scale3d(1, 1, 1);
	}

	30% {
		transform: scale3d(0.75, 1.25, 1);
	}

	40% {
		transform: scale3d(1.25, 0.75, 1);
	}

	50% {
		transform: scale3d(0.85, 1.15, 1);
	}

	65% {
		transform: scale3d(1.05, 0.95, 1);
	}

	75% {
		transform: scale3d(0.95, 1.05, 1);
	}

	100% {
		transform: scale3d(1, 1, 1);
	}
}

.JelloVertical {
	animation: JelloVertical 2s ease 0s 1 normal forwards;
}


@keyframes PulseHeartbeat {
	0% {
		animation-timing-function: ease-out;
		transform: scale(1);
		transform-origin: center center;
	}

	10% {
		animation-timing-function: ease-in;
		transform: scale(0.91);
	}

	17% {
		animation-timing-function: ease-out;
		transform: scale(0.98);
	}

	33% {
		animation-timing-function: ease-in;
		transform: scale(0.87);
	}

	45% {
		animation-timing-function: ease-out;
		transform: scale(1);
	}
}

.PulseHeartbeat {
	animation: PulseHeartbeat 2s ease 0s 1 normal forwards;
}


@keyframes PulseRegular {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

.PulseRegular {
	animation: PulseRegular 2s ease 0s 1 normal forwards;
}


@keyframes PulsePing {
	0% {
		opacity: 0.8;
		transform: scale(0.2);
	}

	80% {
		opacity: 0;
		transform: scale(1.2);
	}

	100% {
		opacity: 0;
		transform: scale(2.2);
	}
}

.PulsePing {
	animation: PulsePing 2s ease 0s 1 normal forwards;
}





@keyframes ShakeHorizontal {

	0%,
	100% {
		transform: translateX(0);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateX(-10px);
	}

	20%,
	40%,
	60% {
		transform: translateX(10px);
	}

	80% {
		transform: translateX(8px);
	}

	90% {
		transform: translateX(-8px);
	}
}

.ShakeHorizontal {
	animation: ShakeHorizontal 2s ease 0s 1 normal forwards;
}




@keyframes ShakeVertical {

	0%,
	100% {
		transform: translateY(0);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateY(-8px);
	}

	20%,
	40%,
	60% {
		transform: translateY(8px);
	}

	80% {
		transform: translateY(6.4px);
	}

	90% {
		transform: translateY(-6.4px);
	}
}

.ShakeVertical {
	animation: ShakeVertical 2s ease 0s 1 normal forwards;
}





@keyframes ShakeRotate {

	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 50% 50%;
	}

	10% {
		transform: rotate(8deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-10deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(10deg);
	}

	80% {
		transform: rotate(-8deg);
	}

	90% {
		transform: rotate(8deg);
	}
}

.ShakeRotate {
	animation: ShakeRotate 2s ease 0s 1 normal forwards;
}

@keyframes ShakeBottom {

	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 50% 100%;
	}

	10% {
		transform: rotate(2deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-4deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(4deg);
	}

	80% {
		transform: rotate(-2deg);
	}

	90% {
		transform: rotate(2deg);
	}
}

.ShakeBottom {
	animation: ShakeBottom 2s ease 0s 1 normal forwards;
}



@keyframes ShakeLeft {

	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 0 50%;
	}

	10% {
		transform: rotate(2deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-4deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(4deg);
	}

	80% {
		transform: rotate(-2deg);
	}

	90% {
		transform: rotate(2deg);
	}
}

.ShakeLeft {
	animation: ShakeLeft 2s ease 0s 1 normal forwards;
}



@keyframes ShakeRight {

	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 100% 50%;
	}

	10% {
		transform: rotate(2deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-4deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(4deg);
	}

	80% {
		transform: rotate(-2deg);
	}

	90% {
		transform: rotate(2deg);
	}
}

.ShakeRight {
	animation: ShakeRight 2s ease 0s 1 normal forwards;
}



@keyframes ShakeTop {

	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 50% 0;
	}

	10% {
		transform: rotate(2deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-4deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(4deg);
	}

	80% {
		transform: rotate(-2deg);
	}

	90% {
		transform: rotate(2deg);
	}
}

.ShakeTop {
	animation: ShakeTop 2s ease 0s 1 normal forwards;
}





@keyframes Vibrate1 {
	0% {
		transform: translate(0);
	}

	20% {
		transform: translate(-2px, 2px);
	}

	40% {
		transform: translate(-2px, -2px);
	}

	60% {
		transform: translate(2px, 2px);
	}

	80% {
		transform: translate(2px, -2px);
	}

	100% {
		transform: translate(0);
	}
}

.Vibrate1 {
	animation: Vibrate1 2s ease 0s 1 normal forwards;
}





@keyframes Vibrate2 {
	0% {
		transform: translate(0);
	}

	20% {
		transform: translate(2px, -2px);
	}

	40% {
		transform: translate(2px, 2px);
	}

	60% {
		transform: translate(-2px, 2px);
	}

	80% {
		transform: translate(-2px, -2px);
	}

	100% {
		transform: translate(0);
	}
}

.Vibrate2 {
	animation: Vibrate2 2s ease 0s 1 normal forwards;
}

@keyframes Vibrate3 {
	0% {
		transform: translate(0);
	}

	10% {
		transform: translate(-2px, -2px);
	}

	20% {
		transform: translate(2px, -2px);
	}

	30% {
		transform: translate(-2px, 2px);
	}

	40% {
		transform: translate(2px, 2px);
	}

	50% {
		transform: translate(-2px, -2px);
	}

	60% {
		transform: translate(2px, -2px);
	}

	70% {
		transform: translate(-2px, 2px);
	}

	80% {
		transform: translate(-2px, -2px);
	}

	90% {
		transform: translate(2px, -2px);
	}

	100% {
		transform: translate(0);
	}
}

.Vibrate3 {
	animation: Vibrate3 2s ease 0s 1 normal forwards;
}



@keyframes WobbleBottom {

	0%,
	100% {
		transform: translateX(0%);
		transform-origin: 50% 50%;
	}

	15% {
		transform: translateX(-30px) rotate(-6deg);
	}

	30% {
		transform: translateX(15px) rotate(6deg);
	}

	45% {
		transform: translateX(-15px) rotate(-3.6deg);
	}

	60% {
		transform: translateX(9px) rotate(2.4deg);
	}

	75% {
		transform: translateX(-6px) rotate(-1.2deg);
	}
}

.WobbleBottom {
	animation: WobbleBottom 2s ease 0s 1 normal forwards;
}



@keyframes WobbleLeft {

	0%,
	100% {
		transform: translateY(0) rotate(0);
		transform-origin: 50% 50%;
	}

	15% {
		transform: translateY(-30px) rotate(-6deg);
	}

	30% {
		transform: translateY(15px) rotate(6deg);
	}

	45% {
		transform: translateY(-15px) rotate(-3.6deg);
	}

	60% {
		transform: translateY(9px) rotate(2.4deg);
	}

	75% {
		transform: translateY(-6px) rotate(-1.2deg);
	}
}

.WobbleLeft {
	animation: WobbleLeft 2s ease 0s 1 normal forwards;
}


@keyframes WobbleRight {

	0%,
	100% {
		transform: translateY(0) rotate(0);
		transform-origin: 50% 50%;
	}

	15% {
		transform: translateY(-30px) rotate(6deg);
	}

	30% {
		transform: translateY(15px) rotate(-6deg);
	}

	45% {
		transform: translateY(-15px) rotate(3.6deg);
	}

	60% {
		transform: translateY(9px) rotate(-2.4deg);
	}

	75% {
		transform: translateY(-6px) rotate(1.2deg);
	}
}

.WobbleRight {
	animation: WobbleRight 2s ease 0s 1 normal forwards;
}

@keyframes WobbleTop {

	0%,
	100% {
		transform: translateX(0%);
		transform-origin: 50% 50%;
	}

	15% {
		transform: translateX(-30px) rotate(6deg);
	}

	30% {
		transform: translateX(15px) rotate(-6deg);
	}

	45% {
		transform: translateX(-15px) rotate(3.6deg);
	}

	60% {
		transform: translateX(9px) rotate(-2.4deg);
	}

	75% {
		transform: translateX(-6px) rotate(1.2deg);
	}
}

.WobbleTop {
	animation: WobbleTop 2s ease 0s 1 normal forwards;
}


@keyframes ScaleUp {
	0% {
		transform: scale(0.5);
		transform-origin: 50% 100%;
	}

	100% {
		transform: scale(1);
		transform-origin: 50% 100%;
	}
}

.ScaleUp {
	animation: ScaleUp 2s ease 0s 1 normal forwards;
}


.card.form {
	animation: ScaleUp 2s ease 0s 1 normal forwards;
}